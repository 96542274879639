import React from 'react';
import { Button, Container, Row, Col, Table } from 'react-bootstrap';

const ScoreboardScreen = ({ playerScores, onContinue, onResetScores, onEndGame, showContinueButton }) => {
    return (
        <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Row className="text-center mb-4">
                <h2>Skor Tablosu</h2>
            </Row>
            <Row className="text-center mb-4">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Oyuncu</th>
                            <th>Pas</th>
                            <th>Tabu</th>
                            <th>Doğru</th>
                        </tr>
                    </thead>
                    <tbody>
                        {playerScores.map((player, index) => (
                            <tr key={index}>
                                <td>{player.name}</td>
                                <td>{player.pass}</td>
                                <td>{player.tabu}</td>
                                <td>{player.correct}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <Row className="text-center">
                {showContinueButton && (
                    <Button variant="primary" onClick={onContinue} className="mb-2">Devam</Button>
                )}
                <Button variant="warning" onClick={onResetScores} className="mb-2">Skoru Temizle</Button>
                <Button variant="danger" onClick={onEndGame}>Oyunu Bitir</Button>
            </Row>
        </Container>
    );
};

export default ScoreboardScreen;
