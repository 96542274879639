export async function fetchQuestion(selectedGroup) {
    try {
        // Doğru URL'yi kullanarak istek yapıyoruz
        const response = await fetch(`q.php?group=${encodeURIComponent(selectedGroup)}`);

        
        // Eğer istek başarısız olursa hata fırlat
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Gelen yanıtı JSON olarak ayrıştır
        const data = await response.json();

        console.log('Fetched data:', data); // Yanıtı kontrol etmek için ekledik

        // Veriyi kontrol edelim
        if (!data || !data.word || !data.bannedWords) {
            throw new Error('Invalid data format');
        }

        // Geçerli bir veri varsa, bu veriyi döndür
        return {
            word: data.word,
            bannedWords: data.bannedWords
        };
    } catch (error) {
        console.error('Failed to fetch the question:', error);
        return {
            word: 'No question available',
            bannedWords: []
        };
    }
}
