import React, { useState } from 'react';
import WelcomeScreen from './components/WelcomeScreen';
import PlayerNamesForm from './components/PlayerNamesForm';
import GameScreen from './components/GameScreen';
import TimerEndScreen from './components/TimerEndScreen';
import ScoreboardScreen from './components/ScoreboardScreen';

function App() {
    const [gameParams, setGameParams] = useState(null);
    const [playerNames, setPlayerNames] = useState(null);

    const [gameStarted, setGameStarted] = useState(false);
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
    const [currentRound, setCurrentRound] = useState(1);
    const [scores, setScores] = useState([]);
    const [showTimerEndScreen, setShowTimerEndScreen] = useState(false);
    const [showScoreboard, setShowScoreboard] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(true);

    const handleStartGame = (params) => {
        console.log('Game Params:', params); // Gelen tüm parametreleri kontrol edin
        setGameParams(params);
    };

    const handleStartWithNames = (names) => {
        setPlayerNames(names);
        setScores(names.map(name => ({ name, correct: 0, tabu: 0, pass: 0 })));
        setGameStarted(true);
        setCurrentRound(1);  // İlk turdan başlat
    };

    const updateScore = (playerIndex, type) => {
        const newScores = [...scores];
        if (type === 'correct') {
            newScores[playerIndex].correct += 1;
        } else if (type === 'tabu') {
            newScores[playerIndex].tabu += 1;
        } else if (type === 'pass') {
            newScores[playerIndex].pass += 1;
        }
        setScores(newScores);
    };

    const handleTimeEnd = () => {
        const nextPlayerIndex = (currentPlayerIndex + 1) % playerNames.length;

        if (nextPlayerIndex === 0) {
            if (currentRound < gameParams.rounds) {
                setCurrentRound(currentRound + 1);
            } else {
                setShowContinueButton(false);
                setShowScoreboard(true);
                return;
            }
        }

        setCurrentPlayerIndex(nextPlayerIndex);
        setShowTimerEndScreen(true);
    };

    const handleConfirmNextPlayer = () => {
        setShowTimerEndScreen(false);
    };

    const handleShowScoreboard = () => {
        setShowTimerEndScreen(false);
        setShowScoreboard(true);
    };

    const handleContinueGame = () => {
        setShowScoreboard(false);
    };

    const handleResetScores = () => {
        setScores(scores.map(score => ({ ...score, correct: 0, tabu: 0, pass: 0 })));
    };

    const handleEndGame = () => {
        setShowContinueButton(false);
        setShowScoreboard(true);
    };

    const handleEndAppGame = () => {
        setGameParams(null);
        setPlayerNames(null);
        setGameStarted(false);
        setCurrentPlayerIndex(0);
        setScores([]);
        setCurrentRound(1);
        setShowTimerEndScreen(false);
        setShowScoreboard(false);
        setShowContinueButton(true);
    };

    return (
        <div>
            {!gameParams ? (
                <WelcomeScreen onStartGame={handleStartGame} />
            ) : !playerNames ? (
                <PlayerNamesForm playerCount={gameParams.playerCount} onStartGame={handleStartWithNames} />
            ) : gameStarted && !showTimerEndScreen && !showScoreboard ? (
                <GameScreen
                    duration={gameParams.duration}
                    playerNames={playerNames}
                    currentPlayerIndex={currentPlayerIndex}
                    scores={scores}
                    currentRound={currentRound}
                    totalRounds={gameParams.rounds}
                    passLimit={gameParams.passLimit}  // Pass limit
                    selectedGroup={gameParams.selectedGroup}  // Seçilen grup bilgisini geçiriyoruz
                    onTimeEnd={handleTimeEnd}
                    updateScore={updateScore}
                    onEndGame={handleEndGame}
                />
            ) : showTimerEndScreen ? (
                <TimerEndScreen
                    currentPlayer={playerNames[currentPlayerIndex]}
                    scores={scores}
                    currentPlayerIndex={currentPlayerIndex}
                    onConfirm={handleConfirmNextPlayer}
                    onShowScoreboard={handleShowScoreboard}
                />
            ) : (
                <ScoreboardScreen
                    playerScores={scores}
                    onContinue={handleContinueGame}
                    onResetScores={handleResetScores}
                    onEndGame={handleEndAppGame}
                    showContinueButton={showContinueButton}
                />
            )}
        </div>
    );
}

export default App;


