import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

const PlayerNamesForm = ({ playerCount, onStartGame }) => {
    const [playerNames, setPlayerNames] = useState([]);

    useEffect(() => {
        // Varsayılan isimleri oluştur
        const defaultNames = [];
        for (let i = 1; i <= playerCount; i++) {
            defaultNames.push(`Oyuncu ${i}`);
        }
        setPlayerNames(defaultNames);
    }, [playerCount]);

    const handleNameChange = (index, value) => {
        const newNames = [...playerNames];
        newNames[index] = value;
        setPlayerNames(newNames);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onStartGame(playerNames);
    };

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Form onSubmit={handleSubmit}>
                {playerNames.map((name, index) => (
                    <Form.Group as={Row} className="mb-3" key={index}>
                        <Form.Label column sm={4}>
                            Oyuncu {index + 1} İsmi
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => handleNameChange(index, e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                ))}
                <Button variant="primary" type="submit" className="mt-3">
                    Oyunu Başlat
                </Button>
            </Form>
        </Container>
    );
};

export default PlayerNamesForm;
