import React from 'react';
import { Button, Container, Row, Col, Table } from 'react-bootstrap';

//const TimerEndScreen = ({ currentPlayer, onConfirm, onShowScoreboard, playerScores = [] }) => {
    const TimerEndScreen = ({ currentPlayer, onConfirm, onShowScoreboard}) => {
    return (
        <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Row className="text-center mb-4">
                <h2>Süre Bitti!</h2>
                <h3>Sıradaki Oyuncu: {currentPlayer}</h3>
            </Row>
            
            {/* Oyuncu Skorları Tablosu */}
            <Row className="mb-4">
                <Col>

                </Col>
            </Row>

            <Row className="text-center mb-4">
                <Button variant="primary" onClick={onConfirm} className="mb-2">Tamam</Button>
                <Button variant="secondary" onClick={onShowScoreboard}>Skor Tablosu</Button>
            </Row>
        </Container>
    );
};

export default TimerEndScreen;
