import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

const WelcomeScreen = ({ onStartGame }) => {
    const [playerCount, setPlayerCount] = useState(2);
    const [rounds, setRounds] = useState(1);
    const [duration, setDuration] = useState(60);
    const [passLimit, setPassLimit] = useState(3);
    const [questionGroups, setQuestionGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('Hepsi');

    useEffect(() => {
        fetch('/sorular/content.json')
            .then(response => response.json())
            .then(data => {
                const groups = data.map(group => group.q);
                setQuestionGroups(['Hepsi', ...groups]);
            })
            .catch(error => console.error('Error fetching content.json:', error));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Selected Group:', selectedGroup); // Seçilen grubu kontrol edin
        onStartGame({ playerCount, rounds, duration, passLimit, selectedGroup });
    };

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Row className="mb-4">
                <h1>Tabu Oyunu</h1>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                        Kaç Kişi Oynayacak?
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            type="number"
                            value={playerCount}
                            min={2}
                            max={8}
                            onChange={(e) => setPlayerCount(parseInt(e.target.value))}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                        Kaç Tur Oynanacak?
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            type="number"
                            value={rounds}
                            min={1}
                            onChange={(e) => setRounds(parseInt(e.target.value))}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                        Süre (saniye)
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            type="number"
                            value={duration}
                            min={10}
                            max={120}
                            onChange={(e) => setDuration(parseInt(e.target.value))}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                        Pas Geçme Sayısı
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            type="number"
                            value={passLimit}
                            min={0}
                            max={99}
                            onChange={(e) => setPassLimit(parseInt(e.target.value))}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                        Soru Grubu Seç
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            as="select"
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                        >
                            {questionGroups.map((group, index) => (
                                <option key={index} value={group}>{group}</option>
                            ))}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                    Başlat
                </Button>
            </Form>
        </Container>
    );
};

export default WelcomeScreen;
