import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { fetchQuestion } from './fetchQuestion';

const GameScreen = ({ duration, playerNames, selectedGroup, currentPlayerIndex, scores, currentRound, totalRounds, onTimeEnd, updateScore, onEndGame, passLimit }) => {
    const [timeLeft, setTimeLeft] = useState(duration);
    const [currentWord, setCurrentWord] = useState("");
    const [bannedWords, setBannedWords] = useState([]);
    const [currentPassCount, setCurrentPassCount] = useState(0); // Pass count for the current round

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearInterval(timerId);
        } else {
            onTimeEnd();
        }
    }, [timeLeft, onTimeEnd]);

    useEffect(() => {
        loadNewQuestion();
        setCurrentPassCount(0);  // Reset pass count at the start of each round
    }, [currentRound]);

    const loadNewQuestion = async () => {
        setCurrentWord("Soru alınıyor...");  // Yükleme mesajını göster
        const { word, bannedWords } = await fetchQuestion(selectedGroup);
        setCurrentWord(word);
        setBannedWords(bannedWords);
    };

    const handlePass = async () => {
        if (currentPassCount >= passLimit) {
            alert('Pas geçme hakkınız doldu!');
            return;
        }
        updateScore(currentPlayerIndex, 'pass');
        setCurrentPassCount(currentPassCount + 1); // Increment the pass count
        await loadNewQuestion();
    };

    const handleTabu = async () => {
        updateScore(currentPlayerIndex, 'tabu');
        await loadNewQuestion();
    };

    const handleCorrect = async () => {
        updateScore(currentPlayerIndex, 'correct');
        await loadNewQuestion();
    };

    const handleEndGame = () => {
        if (window.confirm("Emin misiniz? Oyunu sonlandırmak istiyor musunuz?")) {
            onEndGame();
        }
    };

    return (
        <Container fluid className="d-flex flex-column justify-content-between" style={{ height: '100vh', padding: '0' }}>
            {/* Navbar */}
            <Navbar bg="dark" variant="dark" expand="md">
                <Navbar.Brand href="#">Bildirmece v1.0</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="#" onClick={handleEndGame}>Oyunu Bitir</Nav.Link>
                        <Nav.Link href="#">Skor Listesi</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            {/* Üst Kısım */}
            <Row className="bg-light p-2" style={{ marginTop: '2px' }}> {/* Navbar'ın altından başlaması için marginTop eklendi */}
                <Col xs={4} className="d-flex align-items-center justify-content-start">
                    <h5 className="mb-0">⏳ {timeLeft} s</h5>
                </Col>
                <Col xs={4} className="text-center d-flex align-items-center justify-content-center">
                    <h5 className="mb-0">Tur: {currentRound} / {totalRounds}</h5>
                </Col>
                <Col xs={4} className="text-end d-flex flex-column align-items-end justify-content-center">
                    <h6 className="mb-0">Pas: {currentPassCount} / {passLimit} Tabu: {scores[currentPlayerIndex].tabu} Doğru: {scores[currentPlayerIndex].correct}</h6>
                    <small>{playerNames[currentPlayerIndex]}</small>
                </Col>
            </Row>

            {/* Orta Kısım */}
            <Row className="flex-grow-1 d-flex flex-column justify-content-center align-items-center text-center" style={{ margin: '0', padding: '0 1rem' }}>
                <div style={cardStyle}>
                    <h2 style={wordStyle}>{currentWord}</h2>
                    <ul style={bannedWordsStyle}>
                        {bannedWords.map((word, index) => (
                            <li key={index}>{word}</li>
                        ))}
                    </ul>
                </div>
            </Row>

            {/* Alt Kısım */}
            <Row className="bg-light p-2">
            <div className="fixed-bottom">
                <Col className="d-flex justify-content-between">
                    <Button variant="secondary" size="md" onClick={handlePass} style={{ flex: '1', margin: '0 0.25rem' }}>Pas</Button>
                    <Button variant="danger" size="md" onClick={handleTabu} style={{ flex: '1', margin: '0 0.25rem' }}>Tabu</Button>
                    <Button variant="success" size="md" onClick={handleCorrect} style={{ flex: '1', margin: '0 0.25rem' }}>Doğru</Button>
                </Col>
                </div>
            </Row>
        </Container>
    );
};

// CSS Tarzı için nesneler
const cardStyle = {
    border: '2px solid black',
    borderRadius: '15px',
    padding: '10px',
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '80%',
    maxWidth: '400px',
    margin: 'auto',
    fontFamily: "'Times New Roman', Times, serif",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
};

const wordStyle = {
    fontWeight: 'bold',
    fontSize: '2rem',
    margin: '0 0 0.5rem 0',
};

const bannedWordsStyle = {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    fontSize: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

export default GameScreen;
